<template>
  <div style="display: flex; justify-content: center">
    <el-carousel
      :interval="4000"
      type="card"
      :style="{ width: isHorizontal ? '60%' : '100%' }"
    >
      <el-carousel-item
        :style="{
          backgroundPosition: 'center center',
          backgroundSize: 'auto 100%',
          backgroundImage: `url(${item.img})`,
        }"
        v-for="item in items"
        :key="item.index"
      >
        <h1 text="2xl" justify="center">
          {{ item.title }}
        </h1>

        <p class="more" @click="openInNewTab(item.url)">More...</p>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "VerList",

  data() {
    return {
      isHorizontal: false,

      items: [
        {
          title: "oClock",
          url: "https://assetstore.unity.com/packages/slug/270821",
          img: "thumb/oclock.png",
        },
        {
          title: "Dining",
          url: "https://chaintechdining.web.app/",
          img: "thumb/dining.png",
        },
        {
          title: "oClock",
          url: "https://assetstore.unity.com/packages/slug/270821",
          img: "thumb/oclock.png",
        },
        {
          title: "Dining",
          url: "https://chaintechdining.web.app/",
          img: "thumb/dining.png",
        },
      ],
    };
  },
  mounted() {
    this.checkOrientation();
    window.addEventListener("resize", this.checkOrientation);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      this.isHorizontal = window.matchMedia("(orientation: landscape)").matches;
    },
    openInNewTab(url) {
      console.log(url);
      window.open(url, "_blank");
    },
  },
  props: {},
};
</script>


<style scoped>
h1 {
  color: white;
}

.more {
  color: white;
  position: absolute;
  bottom: 0px;
  right: 5px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.7);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>