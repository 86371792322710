<template>


  <img
    src="@/assets/iconWtxt.svg"
    alt="Coupro"
    :style="{ width: isHorizontal ? '15%' : '30%' }"
  />
  <!-- <h1> Coup Production </h1> -->

  <h3>This is a Place Storing The Donut Holes</h3>
  <p></p>
  <VerList />

  <div class="footer">
    <div></div>

    <div>
      <el-popconfirm
        confirm-button-text="Copy"
        cancel-button-text="Send"
        hide-icon="true"
        title=""
        confirm-button-type="warning"
        cancel-button-type="success"
        @cancel="openInNewTab('mailto:' + csemail)"
        @confirm="copyToClipboard"
      >
        <template #reference>
          <Message class="footer-icon" style="margin-right: 8px" />
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
import VerList from "./components/VerList.vue";

export default {
  name: "App",
  components: {
    VerList,
  },

  mounted() {
    this.checkOrientation();
    window.addEventListener("resize", this.checkOrientation);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkOrientation);
  },

  data() {
    return {
      csemail: "coup.production@gmail.com",
      isHorizontal: false,
    };
  },

  methods: {
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    checkOrientation() {
      this.isHorizontal = window.matchMedia("(orientation: landscape)").matches;
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.csemail)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
  },

  props: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h3 {
  color: #efefef;
  margin: 4vh;
}

body {
  background-color: #ee933c; /* Replace with your desired background color */
}

.el-popper.is-light {
  background: #dc7f28 !important;
  border: 0px !important;
  box-shadow: 0px 2px #ee933c !important;
}

.el-popper.is-light .el-popper__arrow::before {
  border: 0px !important;
  background: #dc7f28 !important;
  box-shadow: 2px 2px #ee933c !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0%;
  width: 100%;
  height: 60px;
  background-color: #dc7f28;

  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-icon {
  width: 2em;
  height: 2em;
}

.el-link {
  margin-right: 8px;
}
.el-link .el-icon--right.el-icon {
  vertical-align: text-bottom;
}
</style>
